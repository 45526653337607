import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from '@/components/ui/NavBar/NavLink';
import { Icon } from '@/components/ui/Icons/Icon';
import { Colors } from '@/constants/Colors';
import { Typography } from '@/components/ui/Typography';
import { BrowseGiftsGroupedCollectionsMobile } from '@/components/ui/NavBar/BrowseGiftsDropdown/BrowseGiftsGroupedCollectionsMobile';
import { GroupedCollection } from '@/types/user';
import {
  AIGenerateCollectionForm,
  AIGenerateCollectionFormValues,
} from '@/components/ui/AIGenerateCollectionForm';
import { AnalyticsService } from '@/services/AnalyticsService';
import { Button } from '@/components/ui/Button';
import { TEST_ID } from '@/constants/Tests';
import { BaseLink } from '@/components/ui/BaseLink';
import { useAppSelector } from '@/hooks/useStore';
import { selectUser } from '@/redux';

interface MobileMenuProps {
  closeMenus(): void;
  onHelpClick(): void;
  onCollectionClick(collection: GroupedCollection): void;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  closeMenus,
  onHelpClick,
  onCollectionClick,
}) => {
  const user = useAppSelector(selectUser);

  const [previousRoute, setPreviousRoute] = useState('/home');

  const handleHelpClick = () => {
    onHelpClick();
    closeMenus();
  };

  const trackAISectionSubmitClick = useCallback(
    (values: AIGenerateCollectionFormValues, aiBudget: number) => {
      AnalyticsService.track('consumer_navbar_gpt_section_submit_clicked', {
        prompt: values.prompt,
        tags: values.tags,
        budget: aiBudget,
      });
    },
    []
  );

  const trackAISectionTryAgainClick = useCallback(
    (values: AIGenerateCollectionFormValues, aiBudget: number) => {
      AnalyticsService.track('consumer_navbar_gpt_section_error_try_again_clicked', {
        prompt: values.prompt,
        tags: values.tags,
        budget: aiBudget,
      });
    },
    []
  );

  const onLoginClick = useCallback(() => {
    AnalyticsService.track('consumer_navbar_login_clicked');
    closeMenus();
  }, [closeMenus]);

  const onSignupClick = useCallback(() => {
    AnalyticsService.track('consumer_navbar_signup_clicked');
    closeMenus();
  }, [closeMenus]);

  useEffect(() => {
    setPreviousRoute(window.location.pathname);
  }, []);

  return (
    <nav className="mobile-menu">
      <NavLink href="/my-gifts" size="sm" onClick={closeMenus}>
        <div className="mobile-menu__title">
          <span className="mobile-menu__title-icon">
            <Icon name="GiftIcon" size={16} color={Colors.indigo[700]} />
          </span>
          <Typography size={16} weight={600} fontFamily="inter">
            My Gifts
          </Typography>
        </div>
      </NavLink>

      <Typography
        size={14}
        weight={500}
        color={Colors.primary[700]}
        fontFamily="dm-sans"
        className="uppercase pl-8"
      >
        Browse Gifts
      </Typography>
      <BrowseGiftsGroupedCollectionsMobile onCollectionClick={onCollectionClick} />

      <NavLink size="sm" onClick={handleHelpClick}>
        <div className="mobile-menu__title">
          <span className="mobile-menu__title-icon">
            <Icon name="QuestionIcon" size={16} color={Colors.indigo[700]} />
          </span>
          <Typography size={16} weight={600} fontFamily="inter">
            FAQ
          </Typography>
        </div>
      </NavLink>

      <div className="pt-4 pb-10 px-4">
        <AIGenerateCollectionForm
          shadowType="gray"
          onSubmitClick={trackAISectionSubmitClick}
          onTryAgainClick={trackAISectionTryAgainClick}
          onSubmitSuccess={closeMenus}
        />
      </div>

      {!user && (
        <div className="mobile-menu__login-container">
          <Button
            href={{ pathname: '/sign-in', query: { previousRoute } }}
            variant="primary"
            className="w-[270px] max-w-full"
            testId={TEST_ID.SIGN_IN_BUTTON}
            onClick={onLoginClick}
          >
            Log In
          </Button>

          <Typography size={14} fontFamily="dm-sans">
            Don't have an account?{' '}
            <BaseLink
              href={{ pathname: '/sign-up', query: { previousRoute } }}
              className="text-indigo-700"
              onClick={onSignupClick}
            >
              Sign up
            </BaseLink>
          </Typography>
        </div>
      )}

      {/*  Styles  */}

      <style jsx>{`
        .mobile-menu {
          @apply flex flex-col gap-y-3 overflow-x-hidden overflow-y-auto py-3;

          &__title {
            @apply flex items-center gap-2 pl-4;
          }

          &__title-icon {
            @apply flex-none p-1 bg-indigo-100 rounded-lg;
          }

          &__login-container {
            @apply flex flex-col gap-2 justify-center items-center text-center;
          }
        }
      `}</style>
    </nav>
  );
};
