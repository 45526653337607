import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { useNavigation } from '@/hooks/useNavigation';
import { BaseImage } from '@/components/ui/BaseImage';
import { BaseLink } from '@/components/ui/BaseLink';
import { UserBar } from '@/components/ui/UserBar';
import { Drawer } from '@/components/ui/Drawer';
import { useChatbot } from '@/hooks/useChatbot';
import { Button } from '@/components/ui/Button';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { AnalyticsService } from '@/services/AnalyticsService';
import { GroupedCollection } from '@/types/user';
import { useDeviceType } from '@/hooks/useDeviceType';
import logoImg from 'public/assets/images/logo.webp';
import { NavLink } from './NavLink';
import { BrowseGiftsDropdown } from './BrowseGiftsDropdown';
import { MobileMenu } from './MobileMenu';

export const NAVBAR_TEST_ID = 'navbar-main-bar';
export const NAVBAR_BROWSE_GIFTS_TEST_ID = 'navbar-main-bar-browse-gifts';

export const MainBar: React.FC = () => {
  const burgerMenuContainerRef = useRef<HTMLDivElement>(null);

  const {
    user,
    isAuthLoading,
    isBurgerMenuOpen,
    isUserMenuOpen,
    closeMenus,
    closeBurgerMenu,
    toggleBurgerMenu,
    setIsUserMenuOpen,
  } = useNavigation();

  const { isSmallDevice } = useDeviceType();
  const { openChatBot } = useChatbot();

  const onCollectionClick = useCallback(
    (collection: GroupedCollection) => {
      if (collection.handle || collection.href) closeMenus();
    },
    [closeMenus]
  );
  const onHelpEvent = useCallback(() => {
    openChatBot();
    AnalyticsService.track('consumer_help_button_clicked');
  }, [openChatBot]);

  const onLoginClick = useCallback(() => {
    AnalyticsService.track('consumer_navbar_login_clicked');
  }, []);

  const onSignupClick = useCallback(() => {
    AnalyticsService.track('consumer_navbar_signup_clicked');
  }, []);

  const onMyGiftsClick = useCallback(() => {
    AnalyticsService.track('consumer_navbar_my_gifts_clicked');
  }, []);

  useOnClickOutside(burgerMenuContainerRef, closeBurgerMenu);

  return (
    <section className="main-bar" data-testid={NAVBAR_TEST_ID}>
      {/* Logo */}
      <div className="main-bar__logo-container">
        <BaseLink href="/home" ariaLabel="Homepage">
          <div className="main-bar__logo-image">
            <BaseImage src={logoImg.src} quality={100} alt="Snappy logo" layout="fill" priority />
          </div>
        </BaseLink>
      </div>

      {/* Nav links */}
      <div className="main-bar__links-container">
        <NavLink href="/my-gifts" onClick={onMyGiftsClick} leftIcon="GiftIcon">
          My Gifts
        </NavLink>
        <BrowseGiftsDropdown onCollectionClick={onCollectionClick} />
        <NavLink leftIcon="QuestionIcon" onClick={onHelpEvent}>
          FAQ
        </NavLink>
      </div>

      {/* Action buttons & links */}
      <div ref={burgerMenuContainerRef} className="main-bar__actions-container">
        <div className={clsx('main-bar__actions-inner', { loading: isAuthLoading })}>
          <div className="main-bar__burger-button-container">
            <NavLink
              leftIcon="SearchIcon"
              isActive={isBurgerMenuOpen}
              size="sm"
              testId={NAVBAR_BROWSE_GIFTS_TEST_ID}
              onClick={toggleBurgerMenu}
            >
              Browse Gifts
            </NavLink>
          </div>

          {user && (
            <UserBar
              userInfo={user}
              displayName={false}
              isOpen={isUserMenuOpen}
              onVisibilityChange={setIsUserMenuOpen}
            />
          )}

          {!user && (
            <div className="main-bar__auth-buttons-container">
              <Button
                href={{ pathname: '/sign-in' }}
                className="main-bar__auth-button"
                variant="secondary"
                size="compact"
                onClick={onLoginClick}
              >
                <span className="main-bar__auth-buttons-text">Log In</span>
              </Button>
              <Button
                href={{ pathname: '/sign-up' }}
                className="main-bar__auth-button"
                variant="primary"
                size="compact"
                onClick={onSignupClick}
              >
                <span className="main-bar__auth-buttons-text">Sign Up</span>
              </Button>
            </div>
          )}
        </div>

        <div className="main-bar__burger-menu-container">
          {isSmallDevice && (
            <Drawer
              classNameDrawer="navbar-burger-menu-drawer"
              indexOfBackdrop={2}
              classNameBackDrop="navbar-burger-menu-backdrop"
              classNameContainer="navbar-burger-menu-container"
              isContainerOpenAbsolute
              withBackdrop
              position="top"
              withCloseIcon={false}
              isOpen={isBurgerMenuOpen}
              onClose={closeMenus}
            >
              <MobileMenu
                onCollectionClick={onCollectionClick}
                closeMenus={closeMenus}
                onHelpClick={onHelpEvent}
              />
            </Drawer>
          )}
        </div>
      </div>

      {/*  Styles  */}

      <style jsx>{`
        .main-bar {
          @apply flex-auto flex items-center justify-between;

          &__logo-container {
            @apply flex-none px-2 xxs:px-6 lg:pb-1;
          }

          &__links-container {
            @apply hidden lg:flex gap-2 flex-none;
          }

          &__actions-container {
            @apply flex-none flex;
          }

          &__actions-inner {
            @apply flex justify-end max-w-full xxs:gap-2 xxs:pr-3 lg:w-[220px] duration-500;

            &.loading {
              @apply opacity-0;
            }
          }

          &__auth-buttons-container {
            @apply max-lg:hidden flex-none lg:flex items-center gap-2;

            :global(.main-bar__auth-button) {
              @apply px-3 py-2 lg:px-6 lg:py-4;
            }
          }

          &__auth-buttons-text {
            @apply font-medium lg:font-bold text-[14px] lg:text-[16px] leading-4;
          }

          &__logo-image {
            @apply w-[100px] h-[23px] lg:w-[150px] lg:h-[33px];
          }

          &__burger-button-container {
            @apply flex lg:hidden items-center;
          }

          :global(.navbar-burger-menu-container) {
            @apply absolute left-0 top-full w-full overflow-hidden h-[calc(100vh-var(--navbar-height))];
          }

          :global(.navbar-burger-menu-backdrop) {
            @apply absolute top-0;
          }

          :global(.navbar-burger-menu-drawer) {
            @apply absolute p-0 border-t border-t-primary-300 border-solid;
            @apply max-h-[calc(100vh-var(--navbar-height))] lg:pb-[calc(var(--navbar-height)*3)];
            @apply left-0 w-full top-0 overflow-y-auto;
          }
        }
      `}</style>
    </section>
  );
};
